import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ConsentTableComponent} from "./components/consent-table/consent-table.component";
import {PreHeaderComponent} from "./components/pre-header/pre-header.component";
import {GenericErrorComponent} from "./components/generic-error/generic-error.component";
import {ConsentComponent} from "./components/consent/consent.component";

@NgModule({
  declarations: [ConsentComponent, ConsentTableComponent, PreHeaderComponent, GenericErrorComponent],
  imports: [
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
  ],
  exports: [ConsentTableComponent, PreHeaderComponent],
})
export class SharedModule {}
