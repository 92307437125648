import {Component, ElementRef, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {CurrentUserService} from "../../services/currentUser/current-user.service";

@Component({
  selector: 'app-pre-header',
  templateUrl: './pre-header.component.html',
  styleUrls: ['./pre-header.component.scss']
})
export class PreHeaderComponent implements OnInit {


  currentLanguage: string;
  supportedLanguages: string[] = ['nl', 'fr', 'de', 'en'];

  constructor(private translateService: TranslateService,
              private currentUserService: CurrentUserService,
              private titleService: Title,
              private elementRef: ElementRef) {
  }

  ngOnInit(): void {

    if (this.currentUserService.appLanguage !== undefined
      && this.currentUserService.appLanguage !== ""
      && this.currentUserService.appLanguage !== null) {
      this.currentLanguage = this.currentUserService.appLanguage.toLowerCase();
    } else {
      this.currentLanguage = localStorage.getItem('lang') || 'en'; // replace with 'nl'?
    }
    this.changeLanguage(this.currentLanguage);
  }


  changeLanguage(language: string) {
    this.currentUserService.appLanguage = language;
    this.currentLanguage = language;
    localStorage.setItem('lang', this.currentLanguage);
    this.translateService.use(this.currentLanguage);
    this.translateService.get('Consents').subscribe((data: string) => {
      this.titleService.setTitle(data);
    });


  }

}
