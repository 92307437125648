import {Component, OnInit} from '@angular/core';
import {CurrentUserService} from "../../services/currentUser/current-user.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {
    this.currentUserService.consentRequest = this.route.snapshot.queryParamMap.get("consent_request");
  }
}


