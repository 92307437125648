<div class="custom-preheader">


  <div class="pre-header-first-half" >
    <ul class="language-switcher-locale-url">
      <li *ngFor="let language of supportedLanguages" style="text-transform: uppercase"
          [ngClass]="{'active': currentLanguage === language}">
        <a [ngClass]="{'active': currentLanguage === language}"
           title="{{language}}" (click)="changeLanguage(language)" data-mce-caret="false">{{language}}</a>
      </li>
    </ul>
  </div>

  <div class="pre-header-second-half">
    <span>
      <span> {{'Other official information and services :' | translate}} </span>
      <a target="_blank" href="http://www.belgium.be/fr">www.belgium.be</a>
      <img src="assets/logos/be-logo.gif" alt=".be">
    </span>
  </div>
</div>
