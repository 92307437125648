import {Component, OnInit} from '@angular/core';
import {
  ConsentService,
  IConsentInfo,
  IConsentType,
  IIdentifier,
  ILabel,
  IUser
} from "../../services/consent/consent.service";
import {HttpErrorResponse} from "@angular/common/http";
import {
  AbstractControl,
  FormArray,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from "@angular/forms";
import {CurrentUserService} from "../../services/currentUser/current-user.service";

@Component({
  selector: 'app-consent-table',
  templateUrl: './consent-table.component.html',
  styleUrls: ['./consent-table.component.scss']
})
export class ConsentTableComponent implements OnInit {
  consentTypesValues: IConsentType[] = [];
  checkboxValues: boolean[] = [];
  form: UntypedFormGroup;
  userInfoValues: IUser;

  constructor(
    private consentService: ConsentService,
    private currentUserService: CurrentUserService,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.retrieveConsentInfos();
  }

  private retrieveUserInfos() {
    this.consentService.getUserInfos().subscribe(
      (response: IUser) => {
        if (response) {
          this.userInfoValues = response;
        }
      },
      (error: HttpErrorResponse) => {
        console.log(`Error! - ${error.message}`);
      });
  }

  private retrieveConsentInfos() {
    this.consentService.getConsentInfos().subscribe(
      (response: IConsentInfo) => {
        if (response) {
          this.retrieveUserInfos();
          this.consentTypesValues = response.consentTypes;
          for (const c in this.consentTypesValues) {
            this.checkboxValues.push(false);
            (this.form.get('checkboxes') as FormArray).push(new FormControl(false));
          }
        }
      },
      (error: HttpErrorResponse) => {
        console.log(`Error! - ${error.message}`);
        this.sendConsentResponse(false);
      });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      checkboxes: this.formBuilder.array([], this.minSelectedCheckboxes())
    });
  }

  minSelectedCheckboxes() {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        const totalSelected = formArray.controls
          .map((control) => control.value)
          .reduce((prev, next) => (next ? prev + next : prev), 0);
        return totalSelected >= 1 ? null : {required: true};
      }

      throw new Error('formArray is not an instance of FormArray');
    };

    return validator;
  }

  onSubmit() {
    if (this.form.valid) {
      this.sendConsentResponse(true);
    }
  }

  sendConsentResponse(isConsentAccepted: boolean): void {
    if (!isConsentAccepted || this.form.valid) {
      let confirmationConsentInfo = {
        identifiers: this.getIdentifiers(),
        consentAccepted: isConsentAccepted
      }
      this.consentService.sendConsentResponse(confirmationConsentInfo).subscribe(
        (res) => {
          ConsentTableComponent.sendConsentResponse(res);
        },
        (error: HttpErrorResponse) => {
          console.log(`Error! - ${error.message}`);
        }
      );
    }
  }

  getTranslation(label: ILabel) {
    return label[this.currentUserService.appLanguage];
  }

  private static sendConsentResponse(res) {
    const myform = document.createElement('form');
    myform.method = 'POST';
    myform.action = res.responseUrl;
    myform.enctype = "application/x-www-form-urlencoded";
    myform.style.display = 'none';
    const hiddenField = document.createElement('input');
    hiddenField.type = "hidden";
    hiddenField.name = "consent_response";
    hiddenField.value = res.jwtResponse;
    myform.appendChild(hiddenField);
    document.body.appendChild(myform);
    myform.submit();
  }

  onRefused() {
    this.sendConsentResponse(false);
  }

  private getIdentifiers() {
    const identifiers: IIdentifier[] = [];
    const consentTypesValues = this.consentTypesValues;
    this.checkboxValues.forEach(function (value, i) {
      if (value) {
        identifiers.push(consentTypesValues[i].identifier);
      }
    })
    return identifiers;
  }
}


