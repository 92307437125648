<form class="container" [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="consentTypesValues.length > 0">
  <div class="table-responsive-lg container-fluid p-lg-5 p-sm-3 mb-2 bg-color full-height">
    <p *ngIf="userInfoValues !== undefined">
      {{ userInfoValues.name }} ({{ userInfoValues.nationalNumber }}) representing company {{ getTranslation(userInfoValues.companyName) }} ({{ userInfoValues.companyNumber }})
    </p>
    <div class="container-fluid card border-1 rounded-3">
      <div class="card-body bg-color col-lg-4 col-md-4 col-sm-4 no-wrap">
        <h3>{{'consent.title' | translate}}</h3>
      </div>
      <div>
        <div class="accordion" id="accordion" formArrayName="checkboxes">
          <div class="accordion-item" *ngFor="let consentType of consentTypesValues; index as i" data-index="#i">
            <div class="accordion-header" id="{{ 'heading-' + i }}">
              <div class='d-flex'>
                <input class="form-check-input" style="z-index:1;margin: 1.1em 0.6em;" type="checkbox" [formControlName]="i" value="" id="{{ 'checkbox-' + i }}" [(ngModel)]="checkboxValues[i]" >
                <button class="accordion-button" style="padding-left: 2rem !important;z-index:0;" data-bs-toggle="collapse" type="button" [attr.data-bs-target]="'#collapse-' + i" href="{{'#collapse-' + i }}" aria-expanded="true" aria-controls="{{'collapse-' + i }}" id="{{'accordion-btn-' + i }}">
                  {{getTranslation(consentType.label)}}
                </button>
              </div>
            </div>
            <div [id]="'collapse-' + i" class="accordion-collapse collapse" [ngClass]="i === 0 ? 'show' : ''" aria-labelledby="{{ 'heading-' + i }}" data-bs-parent="#accordion">
              <div class="accordion-body" [innerHTML]=getTranslation(consentType.longDescription)></div>
            </div>
          </div>
        </div>
        <div *ngIf="form.get('checkboxes')?.errors" class="alert alert-danger mt-3">
          <div *ngIf="form.get('checkboxes')?.errors.required">
            {{ 'consent.scopeMinRequired' | translate}}
          </div>
        </div>
      </div>
      <div>
        <div class="mb-3 float-right">
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid">{{ 'consent.agree' | translate }}</button>
          <button type="button" class="btn btn-secondary" (click)="onRefused()">{{ 'consent.cancel' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</form>
