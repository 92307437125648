<div>
  <app-pre-header></app-pre-header>
</div>

<div class="container mt-3">
  <div *ngIf="data | async as error">
    <div class="container-fluid card border-1 rounded-3">
      <div class="card-body">
        <h3 class="card-title">{{ 'consent.error.' + error.code + '.title' | translate}}</h3>
        <p class="card-text">{{ 'consent.error.' + error.code + '.message' | translate}}</p>
      </div>
    </div>
  </div>
</div>
